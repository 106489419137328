import React from "react";
import extended from "../../assets/images/extended.png";
import premise from "../../assets/images/premise.png";
import cunsolting from "../../assets/images/cunsolting.png";
import projects from "../../assets/images/projects.png";
import engineering from "../../assets/images/engineering.png";
import Sidebar from "../Sidebar";
import Policies from "../Policies";

const datas = [
  {
    id: 1,
    img: extended,
    title: `Extended Team: Wide pool of qualified tech talents to meet your objectives.`,
  },
  {
    id: 2,
    img: premise,
    title: `On-Premise Team: Our team on your premise on a monthly contract.`,
  },
  {
    id: 3,
    img: cunsolting,
    title: `Consulting: Quickly ramp up your team with our experts to meet your short and long term goals.`,
  },
  {
    id: 4,
    img: projects,
    title: `Projects: When you have project(s) with clearly defined scope and requirements.`,
  },
];

const Service = () => {
  return (
    <>
      <div className="h-[95%]  w-full m-2  hidden lg:block  border-[5px] border-black">
        <h1 className="text-[3vw] font-[600] text-center tracking-widest py-2 ">
          Product Engineering Services
        </h1>
        <div className="grid grid-cols-2">
          {datas.map((data) => (
            <div
              key={data.id}
              className={`${
                data.id === 2 || data.id === 3 ? "bg-[#D9D9D9]" : "bg-[#F1F1F1]"
              } flex p-4 px-12 items-center gap-8 tracking-widest`}
            >
              <img src={data.img} width={100} height={100} alt="" />
              <p className="max-[325px] text-[1.3vw]">{data.title}</p>
            </div>
          ))}
        </div>
        <div className="  flex justify-center items-center">
          <div className="w-[80%] h-[12vh] bg-[#FAF9DE] flex items-center gap-5 px-14 rounded-[40px] mt-5 text-[1.1vw] font-[600]">
            <img src={engineering} width={50} height={50} alt="engineering" />
            <p className="tracking-widest">
              Our product engineering services empower your digital
              transformation. Embrace innovation and develop new offerings by
              leveraging the power of digital technologies.
            </p>
          </div>
        </div>
      </div>
      <div className=" lg:hidden">
        <div className="text-[36px] font-[600] text-center tracking-widest bg-[#F1F1F1] py-5 px-20  ">
          Business Units
        </div>
        <div className=" font-[600]  my-20  ">
          {datas.map((data) => (
            <div
              key={data.id}
              className=" flex flex-col h-[40vh] p-5 md:px-44 items-center justify-center tracking-wider gap-5   text-center  text-[18px] font-[600] mt-16 "
            >
              <img
                src={data.img}
                width={78}
                height={78}
                alt="infolitz"
                className="object-contain"
              />
              <p className="px-10 border-[8px] border-black py-3">
                {data.title}
              </p>
            </div>
          ))}
        </div>

        <div className="  flex justify-center items-center mb-20">
          <div className="w-full h-auto p-5  m-5 bg-[#FAF9DE] flex flex-col items-center gap-5 px-14 rounded-[40px] mt-5 text-[16px] text-center font-[600]">
            <img src={engineering} width={70} height={70} alt="engineering" />
            <p className="tracking-widest">
              Our product engineering services empower your digital
              transformation. Embrace innovation and develop new offerings by
              leveraging the power of digital technologies.
            </p>
          </div>
        </div>

        <Sidebar />
        <Policies />
      </div>
    </>
  );
};

export default Service;
