import React from "react";
import contact from "../../assets/images/contact.png";
import location from "../../assets/images/location.png";
import phoneContact from "../../assets/images/phoneContact.png";
import mailContact from "../../assets/images/mailContact.png";
import Policies from "../Policies";
import Sidebar from "../Sidebar";

const Contact = () => {
  return (
    <>
      <div className=" hidden lg:block w-full h-full bg-contain bg-no-repeat p-5 relative">
        <img
          src={contact}
          width={1255}
          height={790}
          alt=""
          className="w-full h-full"
        />
        <div className="flex  justify-around gap-14 absolute bottom-8 px-24 text-[1vw]  ">
          <div className=" text-center font-[600] relative ">
            <img
              src={location}
              width={35}
              height={40}
              alt="location"
              className="absolute -left-6 -top-2"
            />
            KINFRA Hi-Tech Park, HMT Colony, Kalamassery, Ernakulam, Kerala
            683503, India
          </div>
          <div className=" text-center font-[600]">
            7/719-B, Plot 180, Mavelipuram Colony, Kakkanad, Ernakulam, Kerala
            682030, India
          </div>{" "}
          <div className=" text-center font-[600]">
            <div className="flex items-center gap-2">
              <img src={mailContact} width={25} height={30} alt="" />
              <p>info@alcodex.com</p>
            </div>
            <div className="flex items-center gap-2 mt-2">
              <img src={phoneContact} width={25} height={30} alt="" />
              <p>+91 9072393733</p>
            </div>
          </div>
        </div>
      </div>
      <div className=" lg:hidden ">
        <div className=" flex flex-col  md:flex-row items-center text-center gap-5  font-[600] mt-10  p-5 md:px-20 ">
          <img
            src={location}
            width={35}
            height={40}
            alt="location"
            className=" -left-6 -top-2"
          />
          KINFRA Hi-Tech Park, HMT Colony, Kalamassery, Ernakulam, Kerala
          683503, India
        </div>
        <div className=" text-center font-[600] mt-10  p-5 md:px-20">
          7/719-B, Plot 180, Mavelipuram Colony, Kakkanad, Ernakulam, Kerala
          682030, India
        </div>{" "}
        <div className=" flex gap-10 items-center justify-around flex-wrap text-center font-[600] mt-10  p-5 md:px-20">
          <div className="flex items-center gap-2">
            <img src={mailContact} width={25} height={30} alt="" />
            <p>info@alcodex.com</p>
          </div>
          <div className="flex items-center gap-2 mt-2 mb-20">
            <img src={phoneContact} width={25} height={30} alt="" />
            <p>+91 9072393733</p>
          </div>
        </div>
        <Sidebar />
        <Policies />
      </div>
    </>
  );
};

export default Contact;
