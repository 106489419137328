import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function NotFound() {
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to home page after 3 seconds
    const timer = setTimeout(() => {
      navigate("/");
    }, 1000);

    // Clear timeout if the component unmounts
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="flex w-full justify-center flex-col items-center ">
      <h1 className="font-bold">Page Not Found</h1>
      <p className="text-[14px]">Redirecting to home page...</p>
    </div>
  );
}

export default NotFound;
