import React, { useState } from "react";
import policy from "../assets/images/policy.png";

const Policies = () => {
  const [show, setSHow] = useState(false);
  return (
    <>
      <div
        className="hidden lg:flex z-50 items-end  mt-16  flex-col cursor-pointer"
        onClick={() => setSHow(!show)}
      >
        <img
          src={policy}
          width={120}
          height={57}
          alt="policy"
          className="mr-10"
        />
        <button className="text-[12px] text-[#373333]  mr-12 ">
          Policies & T&C
        </button>
      </div>

      <div
        className="flex lg:hidden mx-10  md:mx-36 justify-between items-end  mt-16 pb-2 "
        onClick={() => setSHow(!show)}
      >
        <h6 className="text-[12px] text-[#373333]  ">Policies & T&C</h6>
        <img src={policy} width={120} height={57} alt="policy" />
      </div>

      {show && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg   shadow-xl transform transition-all w-full h-full overflow-scroll  m-5 relative ">
            <button
              className="text-[10px] absolute right-2 top-2"
              onClick={() => setSHow(!show)}
            >
              Close
            </button>

            <div className="p-5  ">
              <h4 className="my-3 ">Privacy Policy</h4>
              <p className="text-[12px]">
                At ALCODEX, we are committed to protecting your privacy and
                ensuring the security of your personal information. Our privacy
                policy outlines how we collect, use, and safeguard your data
                when you visit our website or use our services. We only collect
                information necessary to provide you with a seamless and
                personalized experience, and we never share your information
                with third parties without your consent. Our robust security
                measures protect your data from unauthorized access, and we
                comply with all relevant privacy laws and regulations. By using
                our website, you agree to the terms of our privacy policy,
                ensuring transparency and trust in every interaction.
              </p>

              <h4 className="my-3 ">Cookie Policy</h4>
              <p className="text-[12px]">
                At ALCODEX, we are committed to ensuring that your experience on
                our website is seamless and personalized. To achieve this, we
                use cookies and similar technologies to collect data about your
                usage patterns and preferences. These cookies help us enhance
                site functionality, tailor content, and provide relevant
                advertisements. By continuing to use our website, you consent to
                the use of cookies in accordance with our Cookie Policy.
              </p>

              <h4 className="my-3 ">T&C</h4>
              <p className="text-[12px]">
                Welcome to ALCODEX! By accessing or using our website, you agree
                to be bound by these terms and conditions. The content provided
                on this site is for informational purposes only and may be
                subject to change without notice. We strive to ensure the
                accuracy and completeness of the information, but we do not
                guarantee its reliability. Your use of any information or
                materials on this website is entirely at your own risk, and it
                is your responsibility to ensure that any products, services, or
                information available through this website meet your specific
                requirements. Unauthorized use of this website may give rise to
                a claim for damages and/or be a criminal offense.
              </p>

              <p className="text-[12px] mt-3">
                By using ALCODEX, you agree not to engage in any activity that
                disrupts or interferes with our services, including transmitting
                viruses or any other harmful code. You also agree not to attempt
                to gain unauthorized access to any part of the website or its
                related systems or networks. We reserve the right to restrict or
                terminate your access to the site at any time without notice if
                we believe you have violated these terms. These terms and
                conditions are governed by and construed in accordance with the
                laws of India, and any disputes arising out of your use of the
                website will be subject to the exclusive jurisdiction of the
                courts in that area.
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Policies;
