import React, { useState } from "react";
import navIcon from "../assets/images/navIcon.png";
import close from "../assets/images/close.png";
import { useLocation, useNavigate } from "react-router-dom";

const datas = [
  {
    id: 1,
    title: "OVERVIEW",
    url: "/overview",
  },
  {
    id: 2,
    title: "SERVICES",
    url: "/service",
  },
  {
    id: 3,
    title: "PRODUCTS",
    url: "/products",
  },
  {
    id: 4,
    title: "CUSTOMERS",
    url: "/customers",
  },
  {
    id: 5,
    title: "TEAM",
    url: "/team",
  },
  {
    id: 6,
    title: "CONTACT",
    url: "/contact",
  },
];

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  return (
    <div>
      {location.pathname === "/" ? (
        <div className=" mb-20 lg:mb-0 px-5 text-[50px] lg:text-[4.5vw] font-[700] cursor-pointer ">
          {" "}
          ALCODEX
        </div>
      ) : (
        <>
          <div className="   px-5 font-[700] flex gap-10 justify-between items-center bg-white  lg:bg-[#7C7C73] w-full lg:h-[12vh] lg:text-white ">
            {" "}
            <div
              className="text-[48px] cursor-pointer "
              onClick={() => navigate("/")}
            >
              ALCODEX
            </div>
            <img
              src={show ? close : navIcon}
              width={30}
              height={30}
              alt="navIcon"
              className=" lg:hidden cursor-pointer"
              onClick={() => setShow(!show)}
            />
            <div className="w-full hidden lg:flex justify-around  ">
              {datas.map((data) => {
                return (
                  <div
                    key={data.id}
                    className={`text-[2vw] cursor-pointer hover:scale-105 ${
                      location.pathname === data.url
                        ? "text-yellow-300"
                        : "text-white"
                    } `}
                    onClick={() => navigate(data.url)}
                  >
                    {data.title}
                  </div>
                );
              })}
            </div>
          </div>
          {show && (
            <div className="h-[600px] bg-white px-5 flex flex-col gap-8 mt-10">
              {datas.map((data) => (
                <p
                  className={`text-[20px font-[600]   
              
              ${
                location.pathname === data.url
                  ? "text-yellow-500"
                  : "text-black"
              } 
              
              `}
                  onClick={() => {
                    navigate(data.url);
                    setShow(!show);
                  }}
                >
                  {data.title}
                </p>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Navbar;
