import React from "react";
import Idea from "../../assets/images/Idea.png";
import stpi from "../../assets/images/stpi.png";
import startup from "../../assets/images/startup.png";
import tuv from "../../assets/images/tuv.png";
import msme from "../../assets/images/msme.png";
import kec from "../../assets/images/kec.png";
import be from "../../assets/images/be.png";
import bajaj from "../../assets/images/bajaj.png";
import lt from "../../assets/images/lt.png";
import tata from "../../assets/images/tata.png";
import Sidebar from "../Sidebar";
import Policies from "../Policies";

const Customers = () => {
  return (
    <div className="w-full">
      <div className=" px-5 md:mt-2  h-[95%]  m-2 lg:border-[5px] lg:border-black flex  flex-col items-center justify-center">
        <div className="flex flex-col lg:w-[70%] justify-center">
          <h1 className="md:text-[3vw] font-[600] text-center tracking-widest py-10 md:py-2 ">
            Key Customers
          </h1>
          <div className="  flex flex-wrap items-center gap-10 justify-around relative">
            <img src={kec} width={80} height={80} alt="stpi" />
            <img src={be} width={130} height={130} alt="stpi" />
            <img src={bajaj} width={100} height={100} alt="stpi" />
            <img src={lt} width={100} height={100} alt="stpi" />
            <img
              src={tata}
              width={300}
              height={100}
              alt="stpi"
              className="md:absolute left-11 bottom-2 lg:bottom-0 2xl:left-28 md:w-[25vw] lg:w-[26vw] 2xl:w-[20vw]   "
            />
          </div>

          <h4 className="md:text-[2vw] text-center  py-10 md:py-2">
            KEY ASSOCIATIONS
          </h4>
          <div className="flex flex-wrap items-center gap-10  justify-around ">
            <img src={stpi} width={100} height={100} alt="stpi" />
            <img src={startup} width={100} height={100} alt="stpi" />
            <img src={tuv} width={80} height={80} alt="stpi" />
            <img src={msme} width={80} height={80} alt="stpi" />
          </div>
        </div>
        <div className="flex justify-center items-center mt-16 md:mt-0 mb-20 lg:mb-2 ">
          <div className=" md:w-[80%] p-5 md:h-[12vh] bg-[#FAF9DE] flex  flex-col md:flex-row items-center gap-5 md:px-14 rounded-[40px] mt-5 md:text-[1.1vw] font-[600]">
            <img src={Idea} width={50} height={50} alt="engineering" />
            <p className="tracking-widest">
              “Delivering exactly what you need is not an art but it is the
              perfect blend of quality, professionalism, and communication”
            </p>
          </div>
        </div>
      </div>

      <div className=" lg:hidden">
        <Sidebar />
        <Policies />
      </div>
    </div>
  );
};

export default Customers;
