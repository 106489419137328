import React from "react";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import bg from "../assets/images/bg.png";
import "@fontsource/lexend-mega"; // Defaults to weight 400

const Layout = ({ childern }) => {
  return (
    <div className="h-screen">
      <Navbar />
      <div
        className=" lg:flex  w-full h-[80%]"
        style={{
          fontFamily: "Lexend Mega",
        }}
      >
        <div className="hidden lg:block">
          <Sidebar />
        </div>
        {childern}
      </div>
      <div className=" hidden lg:flex justify-center   ">
        <img src={bg} alt="" className="fixed" />
      </div>
    </div>
  );
};

export default Layout;
