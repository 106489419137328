import React from "react";
import dashboardFinal from "../../assets/video/dashboardFinal.mp4";
import flexibility from "../../assets/images/flexibility.png";
import multitenant from "../../assets/images/multitenant.png";
import remote from "../../assets/images/remote.png";
import application from "../../assets/images/application.png";
import api from "../../assets/images/api.png";
import arrow from "../../assets/images/arrow.png";
import Sidebar from "../Sidebar";
import productsBg from "../../assets/images/productsBg.png";
import Policies from "../Policies";

const ProductsTwo = ({ setTwo }) => {
  return (
    <>
      <div className="hidden lg:block h-[95%] w-full m-2 border-[5px] border-black">
        <img
          src={arrow}
          alt="arrow"
          width={55}
          height={55}
          className="cursor-pointer hover:scale-105 fixed left-32  mt-2 rotate-180"
          onClick={() => setTwo(false)}
        />
        <h1 className="text-[3vw] font-[600] text-center tracking-widest py-2 ">
          Products
        </h1>
        <div className="grid grid-cols-2">
          <div
            className="h-[32vh]  flex justify-center  "
            style={{
              backgroundImage: `url(${productsBg})`,
            }}
          >
            <video autoPlay muted loop className="h-full">
              <source src={dashboardFinal} type="video/mp4" />
            </video>
          </div>
          <div className="bg-[#D7D7D7] text-[1vw] font-[500] py-3">
            <div className="flex gap-3 px-5  items-center">
              <img src={flexibility} width={90} height={90} alt="air" />
              <p className="tracking-widest">
                <span className="font-[700]">Flexibility :</span>A
                cloud-agnostic app designed and developed to be deployed and run
                seamlessly across multiple cloud providers and on premise
                servers
              </p>
            </div>
            <div className="flex gap-3 px-5 items-center mt-3">
              <p className="tracking-widest">
                <span className="font-[700]">Multitenant architecture :</span>
                Enabling us to easily scale up or down based on user demand and
                to reduce downtime for individual users.
              </p>
              <img src={multitenant} width={90} height={90} alt="air" />
            </div>
          </div>

          <div className="flex flex-col gap-3 px-5 items-center">
            <img src={application} width={120} height={120} alt="air" />
            <p className="text-[1.1vw] tracking-widest font-[600] leading-5 px-10">
              Propreitary IoT Application Suite
            </p>
          </div>
          <div className="bg-[#D7D7D7] text-[1vw] font-[500] ">
            <div className="flex gap-3 px-5  items-center">
              <img src={remote} width={80} height={80} alt="air" />
              <p className="tracking-widest">
                <span className="font-[700]">Remote Device Diagnostics :</span>{" "}
                Troubleshoot problems remotely, reducing maintenance costs and
                technician site visits.
              </p>
            </div>
            <div className="flex gap-3 px-5 items-center">
              <p className="tracking-widest">
                <span className="font-[700]">API :</span> Integrate our platform
                with your applications or services to increase value for
                developers and businesses
              </p>
              <img src={api} width={95} height={95} alt="air" />
            </div>
          </div>
        </div>
      </div>
      <div className=" lg:hidden relative">
        <img
          src={arrow}
          alt="arrow"
          width={45}
          height={45}
          className="cursor-pointer hover:scale-105 absolute left-5  mt-6 rotate-180"
          onClick={() => setTwo(false)}
        />
        <div className="text-[36px] font-[600] text-center tracking-widest bg-[#F1F1F1] py-5 px-20  ">
          Products
        </div>
        <div className=" h-[32vh]  flex justify-center  bg-cover ">
          <video autoPlay muted loop className="w-full">
            <source src={dashboardFinal} type="video/mp4" />
          </video>
        </div>
        <div className="border-[8px] bg-[#D7D7D7] border-black mx-5 flex flex-col  p-5 md:px-44 items-center justify-center tracking-wider gap-5 mt-16  text-center  text-[18px] font-[600]  ">
          <img
            src={flexibility}
            width={80}
            height={80}
            alt="infolitz"
            className="object-contain"
          />
          <p className="px-5  py-3">
            <span className="font-[700]">Flexibility :</span> A cloud-agnostic
            app designed and developed to be deployed and run seamlessly across
            multiple cloud providers and on premise servers
          </p>
        </div>
        <div className="border-[8px] bg-[#D7D7D7] border-black mx-5 flex flex-col  p-5 md:px-44 items-center justify-center tracking-wider gap-5 mt-16  text-center  text-[18px] font-[600]  ">
          <img
            src={multitenant}
            width={80}
            height={80}
            alt="infolitz"
            className="object-contain"
          />
          <p className="px-5  py-3">
            <span className="font-[700]">Multitenant architectures :</span>{" "}
            Enabling us to easily scale up or down based on user demand and to
            reduce downtime for individual users.
          </p>
        </div>
        <div className="border-[8px] bg-[#D7D7D7] border-black mx-5 flex flex-col  p-5 md:px-44 items-center justify-center tracking-wider gap-5 mt-16  text-center  text-[18px] font-[600]  ">
          <img
            src={remote}
            width={80}
            height={80}
            alt="infolitz"
            className="object-contain"
          />
          <p className="px-5  py-3">
            <span className="font-[700]">Remote Device Diagnostics :</span>{" "}
            Troubleshoot problems remotely, reducing maintenance costs and
            technician site visits.
          </p>
        </div>
        <div className="border-[8px] bg-[#D7D7D7] border-black mx-5 flex flex-col  p-5 md:px-44 items-center justify-center tracking-wider gap-5 mt-16  text-center  text-[18px] font-[600]  ">
          <img
            src={api}
            width={80}
            height={80}
            alt="infolitz"
            className="object-contain"
          />
          <p className="px-5  py-3">
            <span className="font-[700]">API :</span> Integrate our platform
            with your applications or services to increase value for developers
            and businesses
          </p>
        </div>
        <div className="flex flex-col gap-3 px-5 items-center py-16">
          <img src={application} width={150} height={120} alt="air" />
          <p className="text-center tracking-widest font-[600] leading-5 px-10">
            Propreitary IoT Application Suite
          </p>
        </div>
        <Sidebar />
        <Policies />
      </div>
    </>
  );
};

export default ProductsTwo;
