import { Route, Routes } from "react-router-dom";
import Home from "./components/home/Home";
import Layout from "./components/Layout";
import Overview from "./components/content/Overview";
import Service from "./components/content/Service";
import Product from "./components/content/Product";
import Customers from "./components/content/Customers";
import Team from "./components/content/Team";
import Contact from "./components/content/Contact";
import NotFound from "./components/NotFound";

export default function App() {
  return (
    <Layout
      childern={
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/overview" element={<Overview />} />
          <Route path="/service" element={<Service />} />
          <Route path="/products" element={<Product />} />
          <Route path="/customers" element={<Customers />} />
          <Route path="/team" element={<Team />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      }
    />
  );
}
