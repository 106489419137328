import React from "react";
import arrow from "../../assets/images/arrow.png";
import { useNavigate } from "react-router-dom";
import Sidebar from "../Sidebar";
import Policies from "../Policies";

const Home = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="h-[68%] ml-10 hidden lg:block ">
        <div className="flex items-center gap-10 h-full ">
          <div className="w-[85%] h-full p-10 border-[5px] border-black text-[3vw] font-[600] tracking-[11px] leading-tight text-center relative ">
            We deliver end-to-end product development, empowering businesses to
            innovate, scale, and thrive
            <div className="w-[427px] h-auto px-10 border-[5px] bg-white border-black absolute -right-28 -bottom-16 text-[1.5vw] tracking-widest leading-6 ">
              Our expertise across industries ensures quality, agility, and
              client satisfaction.
            </div>
          </div>
          <img
            src={arrow}
            alt="arrow"
            width={70}
            height={70}
            className="cursor-pointer hover:scale-105"
            onClick={() => navigate("/overview")}
          />
        </div>

        <Policies />
      </div>
      <div className=" lg:hidden ">
        <div className=" mx-10  md:mx-36 h-full p-5 border-[5px] border-black text-[24px] font-[600] text-center">
          We deliver end-to-end product development, empowering businesses to
          innovate, scale, and thrive
        </div>
        <div className="mx-10  md:mx-36 h-full p-5 border-[5px] border-black text-[24px] font-[600] text-center mt-10">
          Our expertise across industries ensures quality, agility, and client
          satisfaction.
        </div>
        <div className="flex justify-center my-16">
          <img
            src={arrow}
            alt="arrow"
            width={120}
            height={120}
            className="cursor-pointer hover:scale-105"
            onClick={() => navigate("/overview")}
          />
        </div>
        <Sidebar />
        <Policies />
      </div>
    </div>
  );
};

export default Home;
