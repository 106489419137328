import React, { useState } from "react";
import envitus from "../../assets/video/envitus.mp4";
import air from "../../assets/images/air.png";
import water from "../../assets/images/water.png";
import communities from "../../assets/images/communities.png";
import agritech from "../../assets/images/agritech.png";
import harbitat from "../../assets/images/harbitat.png";
import arrow from "../../assets/images/arrow.png";
import Sidebar from "../Sidebar";
import productsBg from "../../assets/images/productsBg.png";
import ProductsTwo from "./ProductsTwo";
import Policies from "../Policies";

const Product = () => {
  const [two, setTwo] = useState(false);

  if (two) {
    return <ProductsTwo setTwo={setTwo} />;
  } else {
    return (
      <>
        <div className="hidden lg:block h-[95%] w-full m-2 border-[5px] border-black">
          <img
            src={arrow}
            alt="arrow"
            width={55}
            height={55}
            className="cursor-pointer hover:scale-105 fixed right-10  mt-2"
            onClick={() => setTwo(true)}
          />
          <h1 className="text-[3vw] font-[600] text-center tracking-widest py-2 ">
            Products
          </h1>
          <div className="grid grid-cols-2">
            <div
              className=" h-[32vh]  flex justify-center  bg-cover "
              style={{
                backgroundImage: `url(${productsBg})`,
              }}
            >
              <video controls={true} className="h-full">
                <source src={envitus} type="video/mp4" />
              </video>
            </div>
            <div className="bg-[#D7D7D7] text-[1vw] font-[500] py-3">
              <div className="flex gap-3 px-5  items-center">
                <img src={air} width={80} height={80} alt="air" />
                <p className="tracking-widest">
                  <span className="font-[700]"> Air Quality:</span> A solution
                  with a portable devices with AI powered IoT suite to measure
                  hyperlocal air quality and ambient weather parameters.
                </p>
              </div>
              <div className="flex gap-3 px-5 items-center mt-3">
                <p className="tracking-widest">
                  <span className="font-[700]">Water Quality :</span> A solution
                  to enable people and communities to continuously monitor real
                  time water quality levels to ensure safe water.
                </p>
                <img src={water} width={60} height={60} alt="air" />
              </div>
            </div>

            <div className="flex flex-col gap-3 px-5 items-center">
              <img src={communities} width={150} height={120} alt="air" />
              <p className="text-[1.1vw] tracking-widest font-[600] leading-5 px-10">
                Enabling cities & communities to make cognitive decisions based
                on real time data.
              </p>
            </div>
            <div className="bg-[#D7D7D7] text-[1vw] font-[500] ">
              <div className="flex gap-3 px-5  items-center">
                <img src={agritech} width={67} height={60} alt="air" />
                <p className="tracking-widest">
                  <span className="font-[700]">Agritech :</span> An elegantly
                  designed, and an innovative IoT solution for farmers using
                  in-situ soil monitors for precision farming.
                </p>
              </div>
              <div className="flex gap-3 px-5 items-center">
                <p className="tracking-widest">
                  <span className="font-[700]">Habitat :</span> A solution for
                  early warning and emergency communication with quick action
                  and immediate response systems.
                </p>
                <img src={harbitat} width={90} height={80} alt="air" />
              </div>
            </div>
          </div>
        </div>
        <div className=" lg:hidden relative">
          <img
            src={arrow}
            alt="arrow"
            width={45}
            height={45}
            className="cursor-pointer hover:scale-105 absolute right-5 md:right-10  mt-6"
            onClick={() => setTwo(true)}
          />
          <div className="text-[36px] font-[600] text-start  tracking-widest bg-[#F1F1F1] py-5 px-5 md:px-20  ">
            Products
          </div>
          <video autoPlay muted loop className="w-full">
            <source src={envitus} type="video/mp4" />
          </video>
          <div className="border-[8px] bg-[#D7D7D7] border-black mx-5 flex flex-col  p-5 md:px-44 items-center justify-center tracking-wider gap-5 mt-16  text-center  text-[18px] font-[600]  ">
            <img
              src={air}
              width={80}
              height={80}
              alt="infolitz"
              className="object-contain"
            />
            <p className="px-5  py-3">
              <span className="font-[700]"> Air Quality:</span> A solution with
              a portable devices with AI powered IoT suite to measure hyperlocal
              air quality and ambient weather parameters.
            </p>
          </div>
          <div className="border-[8px] bg-[#D7D7D7] border-black mx-5 flex flex-col  p-5 md:px-44 items-center justify-center tracking-wider gap-5 mt-16  text-center  text-[18px] font-[600]  ">
            <img
              src={water}
              width={80}
              height={80}
              alt="infolitz"
              className="object-contain"
            />
            <p className="px-5  py-3">
              <span className="font-[700]">Water Quality :</span> A solution to
              enable people and communities to continuously monitor real time
              water quality levels to ensure safe water.
            </p>
          </div>
          <div className="border-[8px] bg-[#D7D7D7] border-black mx-5 flex flex-col  p-5 md:px-44 items-center justify-center tracking-wider gap-5 mt-16  text-center  text-[18px] font-[600]  ">
            <img
              src={agritech}
              width={80}
              height={80}
              alt="infolitz"
              className="object-contain"
            />
            <p className="px-5  py-3">
              <span className="font-[700]">Agritech :</span> An elegantly
              designed, and an innovative IoT solution for farmers using in-situ
              soil monitors for precision farming.
            </p>
          </div>
          <div className="border-[8px] bg-[#D7D7D7] border-black mx-5 flex flex-col  p-5 md:px-44 items-center justify-center tracking-wider gap-5 mt-16  text-center  text-[18px] font-[600]  ">
            <img
              src={harbitat}
              width={80}
              height={80}
              alt="infolitz"
              className="object-contain"
            />
            <p className="px-5  py-3">
              <span className="font-[700]">Habitat :</span> A solution for early
              warning and emergency communication with quick action and
              immediate response systems.
            </p>
          </div>
          <div className="flex flex-col gap-3 px-5 items-center py-16">
            <img src={communities} width={150} height={120} alt="air" />
            <p className="text-center tracking-widest font-[600] leading-5 px-10">
              Enabling cities & communities to make cognitive decisions based on
              real time data.
            </p>
          </div>
          <Sidebar />
          <Policies />
        </div>
      </>
    );
  }
};

export default Product;
