import React from "react";
import driving from "../../assets/video/driving.mp4";
import technology from "../../assets/video/technology.mp4";
import expertise from "../../assets/video/expertise.mp4";
import teamOne from "../../assets/images/teamOne.png";
import teamTwo from "../../assets/images/teamTwo.png";
import teamThree from "../../assets/images/teamThree.png";
import Sidebar from "../Sidebar";
import Policies from "../Policies";

const Team = () => {
  return (
    <>
      <div className="hidden lg:block h-[100%] w-full m-2 border-[5px] border-black">
        <h1 className="text-[3vw] font-[600] text-center tracking-widest  ">
          Team
        </h1>
        <div className="grid grid-cols-2">
          <div className="h-full">
            <div className=" h-[45vh]  flex items-center justify-center bg-cover  overflow-hidden">
              <img src={teamThree} width={200} height={349} alt="" />
              <div>
                <img
                  src={teamOne}
                  width={250}
                  height={349}
                  alt=""
                  className="flex-1"
                />
                <img
                  src={teamTwo}
                  width={250}
                  height={349}
                  alt=""
                  className="flex-1"
                />
              </div>
            </div>

            <div className="flex items-center gap-3 px-5 h-[30%] ">
              <p className="text-[1.1vw] tracking-widest font-[500] leading-5 px-10">
                “The strength of the team is each individual member. The
                strength of each member is the team.” -{" "}
                <span className="font-[600]">Phil Jackson</span>
              </p>
            </div>
          </div>
          <div className="bg-[#D7D7D7] h-full text-[1vw] font-[500] py-3">
            <div className="flex gap-5 px-5  items-center">
              <video controls={true} className="w-[16vw]">
                <source src={expertise} type="video/mp4" />
              </video>
              <p className="tracking-widest font-[600] px-10">
                Leveraging expertise from ideation to commercializatio
              </p>
            </div>
            <div className="flex gap-5 px-5  items-center">
              <p className="tracking-widest font-[600] px-10">
                Enabling technology adaptations for market sustenance
              </p>
              <video controls={true} className="w-[16vw]">
                <source src={technology} type="video/mp4" />
              </video>
            </div>{" "}
            <div className="flex gap-5 px-5  items-center">
              <video controls={true} className="w-[16vw]">
                <source src={driving} type="video/mp4" />
              </video>
              <p className="tracking-widest font-[600] px-10">
                Driving enterprise technology innovation using AI & ML
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className=" lg:hidden  w-full m-2">
        <div className="text-[36px] font-[600] text-center tracking-widest bg-[#F1F1F1] py-5 px-20  ">
          Team
        </div>
        <div className="grid grid-cols-1 mt-20">
          <div className="flex flex-col  items-center justify-center bg-cover  overflow-hidden">
            <img src={teamThree} width={200} height={349} alt="" />

            <img
              src={teamOne}
              width={250}
              height={349}
              alt=""
              className="flex-1"
            />
            <img
              src={teamTwo}
              width={250}
              height={349}
              alt=""
              className="flex-1"
            />
          </div>
        </div>
        <div className="flex items-center gap-3 px-5 h-[30%] mt-10 ">
          <p className=" tracking-widest font-[600] text-center leading-5 md:px-10">
            “The strength of the team is each individual member. The strength of
            each member is the team.” -{" "}
            <span className="font-[600]">Phil Jackson</span>
          </p>
        </div>
        <div className="flex flex-col gap-5 px-5  items-center mt-10">
          <video controls={true} className="h-full">
            <source src={expertise} type="video/mp4" />
          </video>
          <p className="tracking-widest font-[600] text-center ">
            Leveraging expertise from ideation to commercializatio
          </p>
        </div>
        <div className="flex flex-col gap-5 px-5  items-center mt-10">
          <video controls={true} className="h-full">
            <source src={technology} type="video/mp4" />
          </video>
          <p className="tracking-widest font-[600]  text-center ">
            Enabling technology adaptations for market sustenance
          </p>
        </div>{" "}
        <div className="flex flex-col gap-5 px-5  items-center mt-10 mb-20">
          <video controls={true} className="h-full">
            <source src={driving} type="video/mp4" />
          </video>
          <p className="tracking-widest font-[600] text-center ">
            Driving enterprise technology innovation using AI & ML
          </p>
        </div>
        <Sidebar />
        <Policies />
      </div>
    </>
  );
};

export default Team;
