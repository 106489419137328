import React from "react";

import whatsApp from "../assets/images/whatsApp.png";
import Instagram from "../assets/images/Instagram.png";
import linkedIn from "../assets/images/linkedIn.png";
import facebook from "../assets/images/facebook.png";
import Phone from "../assets/images/Phone.png";
import mail from "../assets/images/mail.png";
import { useLocation } from "react-router-dom";

const icons = [
  {
    id: 1,
    img: whatsApp,
    url: `//wa.me/+919072393733`,
  },
  {
    id: 2,
    img: Instagram,
    url: "https://www.instagram.com/envitus_solutions/",
  },
  {
    id: 3,
    img: linkedIn,
    url: "https://in.linkedin.com/company/alcodex-technologies-pvt-ltd",
  },
  {
    id: 4,
    img: facebook,
    url: "https://www.facebook.com/alcodex/",
  },
  {
    id: 5,
    img: Phone,
    url: "tel:+91 9072393733",
  },
  {
    id: 6,
    img: mail,
    url: "mailto: info@alcodex.com",
  },
];

const Sidebar = () => {
  const location = useLocation();
  return (
    <>
      <div
        className={` hidden lg:flex h-[88%] ${
          location.pathname !== "/" ? "my-8" : "my-0"
        }   `}
      >
        <div className=" p-3 w-[80px]  flex flex-col gap-2 justify-between bg-[#000000] ">
          {icons.map((data) => (
            <a target="_blank" rel="noreferrer" href={data.url}>
              <img
                key={data.id}
                src={data.img}
                width={40}
                height={40}
                alt={data.img}
                className="cursor-pointer hover:scale-105"
              />
            </a>
          ))}
        </div>
      </div>

      <div className={` lg:hidden  `}>
        <div className=" p-3   flex  gap-2 justify-between bg-[#000000] ">
          {icons.map((data) => (
            <a href={data.url} rel="noreferrer" target="_blank">
              <img
                key={data.id}
                src={data.img}
                width={40}
                height={40}
                alt={data.img}
                className="cursor-pointer hover:scale-105"
              />
            </a>
          ))}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
