import React from "react";
import infolitz from "../../assets/images/infolitz.png";
import envitus from "../../assets/images/envitus.png";
import deployment from "../../assets/images/deployment.png";
import cities from "../../assets/images/cities.png";
import services from "../../assets/images/services.png";
import countries from "../../assets/images/countries.png";
import Sidebar from "../Sidebar";
import Policies from "../Policies";


const datas = [
  {
    id: 1,
    img: infolitz,
    data: `Infolitz focuses on delivering impactful software and product
        engineering services for clients in AI, Machine Learning, and
        enterprise applications.`,
  },

  {
    id: 2,
    img: envitus,
    data: `Envitus is a hyperlocal integrated environment platform to
    continuously monitor the environment in real-time for cognitive
    decision-making..`,
  },
];

const icons = [
  {
    id: 1,
    img: deployment,
    title: `300+
        Envitus  Deployments`,
  },
  {
    id: 1,
    img: cities,
    title: `20+
    Smart Cities Projects`,
  },
  {
    id: 1,
    img: services,
    title: `100+
    Services Projects`,
  },
  {
    id: 1,
    img: countries,
    title: `15+
    Countries with engagements`,
  },
];

const Overview = () => {
  return (
    <>
      <div className="h-[95%]  w-full  m-2  p-3  border-[5px] border-black hidden lg:block">
        <h1 className="text-[3vw] font-[600] text-center tracking-widest ">
          Business Units
        </h1>
        <div className=" font-[600] flex  items-center mt-3 ">
          {datas.map((data) => (
            <div
              key={data.id}
              className=" flex h-[40vh] p-5  items-center justify-center tracking-wider  border-[5px] border-black text-[1.5vw] font-[600]  "
            >
              <img
                src={data.img}
                width={78}
                height={78}
                alt="infolitz"
                className="object-contain"
              />
              <p className="px-10">{data.data}</p>
            </div>
          ))}
        </div>
        <div className="flex justify-around py-3 ">
          {icons.map((data) => (
            <div
              key={data.title}
              className="w-[150px] flex flex-col justify-center items-center"
            >
              <img width={50} height={50} src={data.img} alt={data.title} />
              <h3 className="text-[1.2vw] text-center">{data.title}</h3>
            </div>
          ))}
        </div>
      </div>
      <div className=" lg:hidden">
        <div className="text-[36px] font-[600] text-center tracking-widest bg-[#F1F1F1] py-5 px-20  ">
          Business Units
        </div>
        <div className=" font-[600]  mt-16 ">
          {datas.map((data) => (
            <div
              key={data.id}
              className=" flex flex-col h-[40vh] p-2 md:px-44 items-center justify-center tracking-wider gap-5 mt-16  text-center  text-[18px] font-[600]  "
            >
              <img
                src={data.img}
                width={78}
                height={78}
                alt="infolitz"
                className="object-contain"
              />
              <p className="px- border-[8px] border-black py-3">{data.data}</p>
            </div>
          ))}
        </div>
        <div className="flex flex-col justify-around items-center py-3 mt-12 mb-20 ">
          {icons.map((data) => (
            <div
              key={data.title}
              className="w-[150px] flex flex-col justify-center gap-5 items-center mt-14"
            >
              <img width={100} height={100} src={data.img} alt={data.title} />
              <h3 className="text-[16px] text-center  ">{data.title}</h3>
            </div>
          ))}
        </div>
        {/* <div className="flex justify-center my-16">
          <img
            src={arrow}
            alt="arrow"
            width={120}
            height={120}
            className="cursor-pointer hover:scale-105"
          />
        </div> */}
        <Sidebar />
        <Policies />
      </div>
    </>
  );
};

export default Overview;
